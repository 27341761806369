@use "../../../../App.scss";

.PreviewContainer {
  // box-model
  width: 85vw;
  margin-inline: auto;
  background-color: var(--clr-dark);
  // misc
  border-radius: 2rem;
}

@media (min-width: App.$media-300) {
  .PreviewContainer {
    // box-model
    width: 40rem;
    width: 100%;
  }
}

.PreviewContent {
  // display
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-areas:
    "vid vid"
    "title date";
  gap: 0.5rem;
  // box-model
  align-items: flex-end;

  &.PreviewIsHovered {
    .PreviewTitle {
      // manipulation
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }
    .PreviewDate {
      // manipulation
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }
    .PreviewBanner {
      // manipulation
      opacity: 0;
      transition: opacity 1s ease-in-out;
    }
  }
}
.PreviewBgImg {
  // positioning
  position: relative;
  z-index: 1;
  // grid-area
  grid-row-start: vid;
  grid-column-start: vid;
  grid-row-end: date;
  grid-column-end: date;
  // box-model
  width: 100%;
  height: 100%;
  // misc
  border-radius: 1rem;
}

.PreviewTitle {
  // positioning
  z-index: 3;
  // grid-area
  grid-area: title;
  // box-model
  padding: 0 0 2% 5%;
}

.PreviewDate {
  // positioning
  position: relative;
  z-index: 3;
  // grid-area
  grid-area: date;
  // box-model
  padding: 0 5% 2% 0;
  // type
  text-align: right;
  // manipulation
  transition: opacity 0.25s ease-in-out;
  opacity: 100;
}

.PreviewBanner {
  // positioning
  position: relative;
  z-index: 2;
  // grid-area
  grid-row-start: vid;
  grid-column-start: vid;
  grid-row-end: date;
  grid-column-end: date;
  // box-model
  height: 100%;
  border-radius: 1rem;
  background: linear-gradient(
    0deg,
    rgba(13, 20, 33, 0.694) 0%,
    rgba(0, 0, 0, 0.472) 5%,
    rgba(0, 0, 0, 0.255) 30%,
    rgba(0, 0, 0, 0) 62%,
    rgba(0, 0, 0, 0) 100%
  );
  // manipulation
  opacity: 100;
  // misc
  transition: opacity 0.25s ease-in-out;
}
