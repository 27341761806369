@use "../../App.scss";

.AboutContainer {
  // display
  display: flex;
  flex-direction: column;
  // box-model
  background-color: var(--clr-neutral-400);
  align-items: center;
  
}

.heading {
  &.AboutMin {
    // box-model
    margin-bottom: 0;
  }
}

.AboutContent {
  // display
  display: grid;
  grid-template-columns: 1fr .5fr;
  grid-template-rows: 1fr;
  grid-template-areas: "AboutDesc AboutEnjoy";
  gap: var(--thick-padding);
  width: 100%;
  // box-model
  padding-bottom: var(--padding);
  overflow-x: hidden;

  &.AboutMin {
    // display
    display: flex;
  }
}

.AboutDesc {
  // display
  display: grid;
  justify-self: center;
  // grid-area
  grid-area: AboutDesc;
  // box-model
  max-width: 200ch;
  margin-inline: auto;
  padding-left: var(--thick-padding);

  &.AboutMin {
    // box-model
    max-width: clamp(80ch, 75vw, 340ch);
  }
}

.AboutEnjoy {
  // display
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // grid-area
  grid-area: AboutEnjoy;
  // box-model
  margin-right: auto;
  padding-right: var(--thick-padding);
}

.AboutExpand {
  // display
  display: flex;
  justify-self: center;
  justify-content: center;
  align-items: center;
  // box-model
  height: 1em;
  width: clamp(25rem, 75vw, 75rem);
  background-color: var(--clr-primary-400);
  padding: -var(--thick-padding);
  // misc
  border-radius: 2rem;
}

.AboutImgBox {
  // display
  display: grid;
  align-items: flex-end;
  justify-items: center;
  // box-model
  width: clamp(9rem, 25vw, 40rem);
  min-height: clamp(5rem, 20%, 35rem);
  margin: auto 0;
  background-size: cover;
  background-position: center center;
  // misc
  border-radius: 0.5rem;
  box-shadow: black 0.2rem 0.3rem 0.6rem 0.1rem;
}

.AboutCinema {
  // box-model
  background-image: url("../../Images/About_Enjoy/cinema01.jpg");
  margin-top: 0;
}
.AboutPhilosophy {
  // box-model
  background-image: url("../../Images/About_Enjoy/philosophy01.jpg");
  background-position: 35%;
}
.AboutTravel {
  // box-model
  background-image: url("../../Images/About_Enjoy/travel01.jpg");
  background-position: right;
  margin: auto 0 2rem 0;
}

@media (max-width: App.$media-300) {
  .AboutCinema,
  .AboutTravel {
    // box-model
    margin: auto 0 auto 0;
  }
}