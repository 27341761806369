.ServiceContainer {
  // display
  display: flex;
  flex-direction: column;
  // box-model
  background-color: var(--clr-neutral-400);
}

.ServiceGrid {
  // display
  display: grid;
  gap: 2rem;
  margin-inline: auto;
  // box-model
  max-width: 100vw;
  margin-bottom: 2rem;
  margin-inline: var(--thick-padding);
}
@media (min-width: 37.5em) {
  .ServiceGrid {
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
  }
}

.ServiceEntry {
  // display
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
  // box-model
  margin: 0 auto;
  padding-top: 2rem;
  background-color: var(--clr-primary-200);
  // manipulation
  opacity: 0;
  transition: opacity 0.55s ease-in-out;
  // misc
  border-radius: 1.5rem;
}

.animateServiceEntry {
  // manipulation
  opacity: 1;
  transition: opacity 0.75s ease-in-out;
}

.ServiceCardText {
  // display
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  // box-model
  max-width: 160ch;
  margin: 0 2rem;
  // type
  text-align: center;
}
