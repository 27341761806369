@use "../../App.scss";

.FooterContainer {
  // display
  display: flex;
  flex-direction: column;
  gap: calc(2 * var(--padding));
  // box-model
  margin-top: var(--thick-padding);
  margin-inline: var(--thick-padding);
}

.FooterText {
  // display
  display: flex;
  // box-model
  max-width: 55ch;
}

.FooterTopDiv {
  // display
  display: flex;
  flex-direction: column;
  gap: calc(2 * var(--padding));
  // box-model
  margin-inline: auto;
}

.FooterBotDiv {
  // display
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: App.$media-300) {
  .FooterTopDiv {
    // display
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-template-areas: "footerAbout footerSoc";
    // box-model
    width: clamp(25rem, 70vw, 120rem);
  }
  .FooterAbout {
    // display
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    // box-model
    width: 100%;
  }
  .FooterSoc {
    // display
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .FooterDivider {
    // box-model
    min-width: clamp(25rem, 70vw, 120rem);
  }
}

.FooterAbout {
  // grid-area
  grid-area: footerAbout;
}

.FooterSoc {
  // grid-area
  grid-area: footerSoc;
}

.FooterTitle {
  // manipulation
  text-transform: uppercase;
}

.FooterDivider {
  // display
  display: inline-block;
  // box-model
  width: 25rem;
  margin-bottom: calc(2 * var(--padding));
  border-top: 0.4rem solid var(--clr-primary-400);
}

.FooterCopyright {
  // display
  display: flex;
  justify-content: center;
  align-items: center;
  // box-model
  margin-bottom: var(--thick-padding);
}

.FooterUnderline {
  // box-model
  border-bottom: 0.1rem solid var(--clr-neutral-400);
}
