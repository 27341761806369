@use "../../App.scss";

.NavContainer {
  // positioning
  --nav-height: 0;
  position: sticky;
  top: var(--nav-height);
  z-index: 20;
  // box-model
  padding: 0.5em 0;
  width: 100%;
  background-color: var(--clr-navbar);
  // type
  text-align: right;
  // manipulation
  transition: top 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  // misc
  outline: 3px solid var(--clr-navbar);
}

.NavContainer.NavDown {
  // positioning
  --nav-height: var(--NavDynamicHeight);
}

.NavLogo {
  // display
  display: flex;
  justify-content: left;
  align-items: center;
  // box-model
  height: 3rem;
  margin-inline: 1em;
}

.NavLinkContainer {
  // positioning
  position: absolute;
  top: 100%;
  left: 0;
  // box-model
  width: 100%;
  background-color: var(--clr-navbar);
  // type
  text-align: left;
  // manipulation
  transform: scale(1, 0);
  transform-origin: top;

  &.NavShowing {
    // manipulation
    transform: scale(1, 1);
    transition: transform 400ms ease-in-out;

    .NavListItem {
      // manipulation
      opacity: 1;
      transform: scale(1, 1);
      transform-origin: bottom;
      transition: opacity 1050ms ease-in-out 300ms;
      transition: transform 300ms ease-in-out 200ms;
    }
  }
}

.NavListItem {
  // display
  display: flex;
  justify-content: center;
  // box-model
  margin: 0 1em;
  margin-bottom: 1em;
  // type
  font-weight: var(--fw-skinny);
  // manipulation
  text-transform: uppercase;
  opacity: 0;
  transform: scale(1, 0);
  transition: opacity 250ms ease-in-out;
}

.NavListItem:hover {
  // type
  font-weight: var(--fw-bold);

  a {
    // box-model
    padding-inline: 1em;
    background-color: var(--clr-navbar-hover);
    // misc
    border-radius: 1.5rem;
    cursor: pointer;
  }
}

.NavMobileToggler {
  // display
  display: flex;
  align-items: center;
  // positioning
  position: absolute;
  top: 0;
  right: 0;
  // box-model
  height: 100%;
  margin-inline: 1em;
}

@media (min-width: App.$media-300) {
  .NavMobileToggler {
    // display
    display: none;
  }
  .NavContainer {
    // display
    display: grid;
    grid-template-columns: 1fr auto minmax(40rem, 5fr) 1fr;
    grid-template-rows: auto;
  }
  .NavLogo {
    // display
    display: flex;
    justify-content: left;
    // grid-area
    grid-column: 1;
    grid-row: 1;
    // box-model
    min-width: 5rem;
    margin-left: var(--thick-padding);
  }

  .NavLinkContainer {
    // display
    all: unset;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    // grid-area
    grid-column: 2 / span 3;
    grid-row: 1;

  }
  .NavListItem {
    // display
    all: unset;
    display: grid;
    // box-model
    padding-inline: 1rem;
    // type
    color: var(--clr-neutral-400);
    // manipulation
    opacity: 1;
  }

  .NavListItem:hover {
    // display
    all: unset;
    a {
      // display
      all: unset;
      // box-model
      padding-inline: 1rem;
      background-color: var(--clr-navbar-hover);
      // type
      color: var(--clr-neutral-400);
      // misc
      border-radius: 1.5rem;
      cursor: pointer;
    }
  }
}

@media (min-width: App.$media-600) {
  .NavLinkContainer {
    // grid-area
    grid-column: 1 / span 4;
  }
}
