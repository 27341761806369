@use "../../App.scss";

.SampleContainer {
  // display
  display: flex;
  flex-direction: column;
  // box-model
  gap: 2rem;
  padding: 0 0 2rem 0;
  background-color: var(--clr-primary-400);
}

.SamplePreviewContainers {
  // display
  display: flex;
  flex-direction: column;
  // box-model
  gap: 2rem;
  grid-auto-rows: minmax(0, 1fr);
}

.SampleDivider{
   display: flex;
  justify-content: center;
  font-family: var(--ff-heading); 
  font-weight: var(--ff-bold);
}

$max-col-count: 2;
@media (min-width: App.$media-300) {
  .SamplePreviewContainers {
    // display
    display: grid;
    gap: 2rem;
    grid-auto-columns:minmax(0, 1fr);
    grid-auto-flow: column;
    // box-model
    margin: 0 2rem;
  }

  @for $i from 1 through $max-col-count {
    .SamplePreview:nth-child(#{$max-col-count}n + #{$i}) {
      // grid-area
      grid-column: $i;
    }
  }
}

$max-col-count: 3;
@media (min-width: App.$media-600) {
  .SamplePreviewContainers {
    // display
    display: grid;
    gap: 3rem;
    grid-auto-columns:minmax(0, 1fr);
    grid-auto-flow: column;
    // box-model
    margin: 0 3rem;
  }

  @for $i from 1 through $max-col-count {
    .SamplePreview:nth-child(#{$max-col-count}n + #{$i}) {
      // grid-area
      grid-column: $i;
    }
  }
}