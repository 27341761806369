.FilmInstanceContainer {
  // display
  display: flex;
  flex-direction: column;
  // box-model
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding-inline: var(--thick-padding);
  background: #ffefba; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #fdf9ec,
    #ffe8ba
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #fcf7e8,
    #ffe8ba
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.FilmInstanceOpenAside {
  // display
  display: flex;
  flex-direction: column;
}
.FilmInstanceAsideContainer {
  // display
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: stretch;
  // box-model
  padding: calc(1.3 * var(--thick-padding));
  &.isFullScreen {
    // box-model
    height: -webkit-fill-available;
    height: 100vh;
  }
}

.FilmInstanceAsideClose {
  // display
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  // box-model
  min-height: 10%;
  width: 100%;
  margin-bottom: var(--padding);
}

.FilmInstanceAsideBottomSpacer {
  // display
  display: inline-block;
  // box-model
  padding-top: 10vh;
}

.FilmInstanceAsideContent {
  // display
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // box-model
  height: 100%;
  margin-block: auto;
  // type
  text-align: center;
}

.FilmInstanceAsideTitle {
  // display
  display: flex;
  justify-content: center;
  align-items: center;
}
.FilmInstanceAsideBody {
  // display
  display: flex;
  justify-content: center;
  align-items: center;
  // box-model
  max-width: 70vw;
}

.FilmInstanceTopNav {
  // display
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // box-model
  margin-block: var(--thick-padding);
}

.FilmInstanceId {
  // display
  display: flex;
  flex-direction: column;
}

.FilmInstanceInfo {
  // display
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  // box-model
  height: 20%;
  margin: 5% 0 5% 15%;
  margin-inline: auto;

  .FilmInstanceInfoButton {
    // type
    text-decoration: underline;
    text-decoration-thickness: 0.4rem;
  }
}

.player-wrapper {
  // positioning
  position: relative;
  z-index: 2;
  // box-model
  padding-top: 56.25%;
  // misc
  overflow: hidden;
  &.skinny {
    // box-model
    padding-top: 42%;
  }
}

.react-player {
  // positioning
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.FilmInstancePlayerContainer {
  // positioning
  position: relative;
  z-index: 3;
  // box-model
  width: clamp(23rem, 70vw, 90rem);
  max-height: 40vh;
  max-width: 71vh;
  margin: auto;
  background-color: var(--clr-primary-900);
  // misc
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: black 4px 4px 5px 1px, inset 0px 0px 90px black,
    inset 0px 0px 90px black, inset 0px 0px 90px black;
}

.FilmInstanceBotNav {
  // display
  display: flex;
  justify-content: space-around;
  align-items: center;
  // box-model
  height: 40%;
  margin-block: auto var(--thick-padding);
}
.FilmInstanceVimeo {
  // display
  display: flex;
  align-items: center;
  // box-model
  height: 100%;
}

aside {
  // positioning
  position: fixed;
  top: 0;
  // box-model
  width: 18.75rem;
  min-height: 100%;
  height: -webkit-fill-available;
  z-index: 4;
  background-color: var(--clr-primary-900);
  // misc
  overflow: hidden;
  overflow-y: hidden;
}
