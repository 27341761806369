@use "../../App.scss";

.LandingPoster {
  // display
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(8, minmax(0, 1fr));
  gap: 0.2rem;
  grid-template-areas:
    "r1c1 r1c2 r1c3 r1c4"
    "r2c1 r2c2 r2c3 r2c4"
    "r3c1 r3c2 r3c3 r3c4"
    "r4c1 r4c2 r4c3 r4c4"
    "r5c1 r5c2 r5c3 r5c4"
    "r6c1 r6c2 r6c3 r6c4"
    "r7c1 r7c2 r7c3 r7c4"
    "r8c1 r8c2 r8c3 r8c4";
  // box-model
  height: calc(100vh - clamp(5rem, -14.5rem + 26vw, 6.3rem));
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../Images/Backgrounds/BrunoPoster.jpg");
  background-size: 100% 100%;
}

@media (min-width: App.$media-350) {
  .LandingPoster {
    // box-model
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("../../Images/Backgrounds/BrunoSquare.jpg");
  }
}
@media (min-width: App.$media-600) {
  .LandingPoster {
    // box-model
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("../../Images/Backgrounds/BrunoLaptop.jpg");
  }
}
@media (min-width: App.$media-800) {
  .LandingPoster {
    // box-model
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("../../Images/Backgrounds/BrunoDesktop.jpg");
  }
}

.Typewriter {
  // display
  display: flex;
  justify-content: center;
  align-items: center;
  // grid-area
  grid-row-start: r1c2;
  grid-column-start: r1c2;
  grid-row-end: r1c3;
  grid-column-end: r1c3;
  // box-model
  padding-top: 1.5em;
  // type
  font-size: var(--fs-title);
  font-family: var(--ff-body);
}

.LandingMyName {
  // display
  display: flex;
  justify-self: center;
  align-items: center;
  // grid-area
  grid-row-start: r3c1;
  grid-column-start: r3c1;
  grid-row-end: r4c4;
  grid-column-end: r4c4;
  // box-model
  max-width: 74.7%;
  // type
  text-align: center;
}

.LandingLogline {
  // display
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  // grid-area
  grid-row-start: r4c1;
  grid-column-start: r4c1;
  grid-row-end: r4c4;
  grid-column-end: r4c4;
  // box-model
  max-width: 84%;
  margin-top: 4.5rem;
  // type
  text-align: center;
  letter-spacing: 6.83px;
}

.LandingResumeContainer {
  // display
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // grid-area
  grid-row-start: r7c1;
  grid-column-start: r7c1;
  grid-row-end: r7c4;
  grid-column-end: r7c4;
  // type
  font-size: var(--fs-label);
  font-family: var(--ff-body);
  color: var(--clr-neutral-400);
  letter-spacing: 0.18rem;
}

.LandingResume {
  // display
  display: flex;
  justify-content: center;
  align-items: center;
  // box-model
  margin-top: var(--padding);
  padding-block: 0.15em;
  padding-inline: var(--padding);
  background-color: var(--clr-neutral-400);
  // type
  text-align: center;
  font-weight: var(--fw-regular);
  color: var(--clr-primary-900);
  // manipulation
  text-transform: lowercase;
  // misc
  box-shadow: var(--shadow-halation);
  border-radius: 2rem;
}

.LandingLinks {
  // box-model
  margin: 0;
  padding: 0;
}

.LandingCopyright {
  // display
  display: flex;
}

@media (max-width: App.$media-250) {
  .LandingCopyright {
    // display
    flex-direction: column;
  }
}
